export default [
    "有趣的地方都想去​|比如你的世界",
    "我想在你那里买一块地|买什么地？|买你的死心塌地",
    "你知道你和星星有什么区别吗？|星星在天上|而你在我心里",
    "我十拿九稳|就只差你一吻了",
    "可爱不是长久之计|可爱我是",
    "小猪佩奇|你配我",
    "有谣言说我喜欢你|我澄清一下|那不是谣言",
    "只许州官放火|不许…|不许你离开我",
    "你昨天晚上应该很累吧|因为你在我梦里一直跑个不停",
    "我觉得你接近我就是在害我|害得我好喜欢你呀",
    "你今天好奇怪|怪！可！爱！的！",
    "我觉得我好花心|你每天的样子我都好喜欢",
    "你有打火机嘛？|没有？|那你是如何点燃我的心的",
    "我说不清我为什么爱你|我只知道|只要有你|我就不可能爱上别人",
    "我喜欢你|像你妈打你|不讲道理",
    "知道你为什么这么冷吗？|因为你没有像我这么暖的对象在身边啊。",
    "无事献殷勤|非…|非常喜欢你",
    "子曰：三思而后行|1、2、3、|嗯～我喜欢你。",
    "小女子不才|掐指一算|公子今生缺我",
    "你有地图吗？|我在你的眼睛里迷路了。",
    "饭在锅里|我在床上",
    "你知道我最喜欢什么神吗？|是你的眼神",
    "你要是丑点|我或许可以带你逛街看电影吃西餐散步看星星看月亮|从诗词歌赋谈到人生哲学|可你长的那么好看，让我只想和你恋爱",
    "我房租到期了|可以去你心里住吗？",
    "要是我和你生一个孩子你觉得他会是什么座？|什么座？双子座？|不，我们的杰作。",
    "你可以笑一个吗？|为什么啊？|因为我的咖啡忘加糖了",
    "你想喝点什么？|我想呵护你。",
    "我觉得你长得像我一个亲戚|？？？|我妈的儿媳妇",
    "你知道情人眼里出什么吗？|西施？！|不，出现你。",
    "你最近是不是又胖了？|没有啊，为什么这么说？|那你为什么在我心里的分量越来越重了呢？",
    "落叶归根|你归我",
    "苦海无涯|回头是我",
    "不想撞南墙了|只想撞撞先生胸膛",
    "你上辈子一定是碳酸饮料吧|不然我怎么一看到你就开心地冒泡呢",
    "你会弹钢琴吗？|不会|那你是怎么撩动我的心弦的呢",
    "第一次见到你时|上帝在我耳旁说了几个字|在劫难逃",
    "你知道喝什么酒最容易醉吗？|是你的天长地久",
    "你属什么？|我属虎。|你不要再骗人了，你属于我。",
    "你是什么星座?双子座吗?|不是，我是为你量身定做。",
    "你知道我最大的缺点是什么吗？|是缺点你",
    "如果我把你推到花园里面|我就会找不到你|因为你像花儿一样美丽|",
    "有时候生活有些苦难|你不要去抱怨|抱我就好了",
    "我点的东西怎么还没来？|什么东西？|我们的未来。",
    "你的脸上有点东西|有什么？|有点漂亮",
    "我一点也不想你|一点半再想你",
    "你知道我的心在哪边么？|左边啊|不，在你那边",
    "我们来玩木头人不许动吧|好！我输了|为什么？|因为我心动了",
    "莫文蔚的阴天|孙燕姿的雨天|周杰伦的晴天|都不如你和我聊天",
    "我想你应该很忙|所以你看到前三个字就好了",
    "甜有100种方式|吃糖|蛋糕|还有98次想你",
    "你知道我喜欢吃什么吗？|不知道|我喜欢痴痴地望着你",
    "你猜一下我是什么星座？|不知道|我是什么都想为你做！",
    "你是什么血型？|A型啊|不，你是我的理想型",
    "我知道有三个人喜欢你|谁呀|我呀！我呀！我呀！",
    "我刚发现我们有一个共同好友！|是谁？|丘比特！",
    "我和你没什么好谈的|除了谈恋爱",
    "不思进取|思你",
    "众生皆苦|你是草莓味！",
    "见什么世面|见见你就好了！",
    "好好照顾自己​​​​|不行就让我来照顾你",
    "眼里都是你|亿万星辰犹不及",
    "最近手头有点紧|想借你的手牵一牵",
    "人总是会变的|从一开始的喜欢你|到后来的更喜欢你",
    "一想到昨天的我也喜欢你|今天的我就吃醋了",
    "希望有一天|你来到这个城市|是因为想起了我",
    "你为什么要害我？|我害你？|害我这么喜欢你",
    "游乐园里有个可以骑在上面旋转的，那个叫旋转什么？|木马|Mua~",
    "你知道牛肉要怎么吃才好吃吗？|我喂你吃",
    "我觉得你这个人不适合谈恋爱|为什么？|你适合结婚",
    "一生无定|遇你之所|是为乡",
    "要不我们去喝酒吧|喝我们的喜酒",
    "你一定很孤独吧|因为你一直住在我心里",
    "你眼瞎吗|撞我心口上了",
    "遍地都是月光|可月亮就你一个",
    "你是哪里人|杭州人|不，你是我的心上人",
    "我还是喜欢你|像小时候吃辣条|不看日期",
    "“你能不能闭嘴”|“我没有说话啊”|“那为什么我满脑子都是你…”",
    "你闻到什么味道了吗？|没有啊|怎么你一出来空气都是甜的了",
    "我有罪！|什么？|我正在你的芳心纵火",
    "你是书吧？|越看越想睡",
    "海上月是天上月|眼前人是心上人",
    "我觉得你好像一款游戏|什么游戏?|我的世界",
    "我身体很好|可以扛米袋子，扛煤气罐|可就是扛不住想你",
    "你知道我最珍贵的是什么吗？|是上一句话的第一个字",
    "今天不是你死就是我死|你可爱死了|我爱死你了",
    "“洁白的婚纱，手捧着鲜花”这首歌歌名是什么?|咱们结婚吧|好啊！",
    "你可以帮我洗一个东西吗|洗什么？|喜欢我啊",
    "天气这么冷，你知道怎么取暖吗？|不知道|我也不知道，但我知道怎么娶你",
    "跟你说一个坏消息|什么坏消息？|我对你的思想已经不单纯了",
    "以后你的口红我包了|但记得每天还我一点点",
    "这是校服|这是迷彩服|你是我的小幸福",
    "我认为我是一个直男|为什么？|因为我一直爱着你",
    "今天和喜欢的人说了一句话|说了啥？|“今天和喜欢的人说了一句话”",
    "你的眼睛真好看，里面藏着山川湖海，日月星辰|我的眼睛更好看|里面藏着你",
    "既见君子，云胡不喜|明天生动而具体|有且仅有一个你",
    "月亮不会奔向你|但我会|不负万里的那种",
    "你摸摸我什么面料子|面料怎么了？|是不是做你男友的料子",
    "以后我只能称呼你为您了|因为，你在我心上",
    "我的手被划了一口子|你也划一下|这样我们就是两口子了",
    "土豆会变成土豆泥，猜猜我会变成什么呢？|不知道诶|我爱泥",
    "我想吃碗面|什么面？|你的心里面",
    "我对你的爱|就像拖拉机上山|轰轰烈烈",
    "你猜我坐飞机喜欢靠走道还是靠窗|靠窗？|不对,我喜欢靠着你",
    "地球和太阳都不是宇宙的中心|你才是",
    "我小时候想成为科学家，可现在不一样了|现在怎么了?|现在想和你成家",
    "我觉得我是一个单调的人|为什么？|因为我只喜欢你",
    "你猜我爱喝酒还是爱打游戏|爱打游戏吧?|不，爱你",
    "你好宅啊|没有啊|可你呆在我的心里就没动过",
    "你知道什么是重要吗？|就是你无论多重|我都要你！"
  ];
