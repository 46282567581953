import Drawing from './drawing';
import Point from './point';
// 分析出图形的像素


let gap = 13;
var shapeCanvas = document.createElement("canvas"),
  shapeContext = shapeCanvas.getContext("2d"),
  fontSize = 500,
  fontFamily = "Avenir, Helvetica Neue, Helvetica, Arial, sans-serif";
// document.body.appendChild(shapeCanvas);

function fit(width, height) {
  shapeCanvas.width = Math.floor(width / gap) * gap;
  shapeCanvas.height = Math.floor(height / gap) * gap;
  shapeContext.fillStyle = "red";
  shapeContext.textBaseline = "middle";
  shapeContext.textAlign = "center";
}

function processCanvas(
  maxWidth = shapeCanvas.width
) {
  var pixels = shapeContext.getImageData(
      0,
      0,
      maxWidth,
      shapeCanvas.height
    ).data,
    dots = [],
    fx = maxWidth,
    fy = shapeCanvas.height,
    x = 0,
    y = 0,
    w = 0,
    h = 0;
  for (var p = 0; p < pixels.length; p += 4 * gap) {
    if (pixels[p + 3] > 0) {
      // 有颜色
      dots.push(
        new Point({
          x: x,
          y: y,
        })
      );

      w = Math.max(x, w);
      h = Math.max(y, h);
      fx = Math.min(x, fx);
      fy = Math.min(y, fy);
    }

    x += gap;

    if (x >= maxWidth) {
      x = 0;
      y += gap;
      p += gap * 4 * maxWidth;
    }
  }
  console.log(dots);
  return { dots, w: w + fx, h: h + fy };
}

function setFontSize(s) {
  shapeContext.font = "bold " + s + "px " + fontFamily;
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function init (newGap, width, height) {
  gap = newGap;
  fit(width, height);
  window.addEventListener("resize", fit.bind(null, width, height));
}

function imageFile (url, callback) {
  var image = new Image(),
    a = Drawing.getArea();

  image.onload = function () {
    shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
    shapeContext.drawImage(this, 0, 0, a.h * 0.6, a.h * 0.6);
    callback(processCanvas());
  };

  image.onerror = function () {
    callback(letter("What?"));
  };

  image.src = url;
}

function circle (d) {
  var r = Math.max(0, d) / 2;
  shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
  shapeContext.beginPath();
  shapeContext.arc(r * gap, r * gap, r * gap, 0, 2 * Math.PI, false);
  shapeContext.fill();
  shapeContext.closePath();

  return processCanvas();
}

function letter (l) {

  setFontSize(fontSize);
  // 从原来的， 缩小宽度，缩小高度之中，取出最小的值
  let s = Math.min(
    fontSize,
    (shapeCanvas.width / shapeContext.measureText(l).width) * 0.8 * fontSize,
    (shapeCanvas.height / fontSize) * (isNumber(l) ? 1 : 0.45) * fontSize
  );
  setFontSize(s);

  shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
  shapeContext.fillText(l, shapeCanvas.width / 2, shapeCanvas.height / 2);

  return processCanvas();
}

function letterOverflow (l) {
  shapeContext.textBaseline = "alphabetic";
  shapeContext.textAlign = "left";
  setFontSize(fontSize);
  let s = Math.min(
    fontSize,
    (shapeCanvas.height / fontSize) * (isNumber(l) ? 1 : 0.45) * fontSize
  );
  const textWidth = shapeContext.measureText(l).width;
  shapeCanvas.width = Math.floor((textWidth + 130) / gap) * gap;
  setFontSize(s);
  shapeContext.clearRect(0, 0, shapeCanvas.width, shapeCanvas.height);
  shapeContext.fillStyle = "red";
  shapeContext.fillText(l, 130, shapeCanvas.height / 2);
  return processCanvas();
}

function rectangle (w, h) {
  var dots = [],
    width = gap * w,
    height = gap * h;

  for (var y = 0; y < height; y += gap) {
    for (var x = 0; x < width; x += gap) {
      dots.push(
        new Point({
          x: x,
          y: y,
        })
      );
    }
  }

  return { dots: dots, w: width, h: height };
}

const shapeBuilder = {
  init,
  circle,
  letter,
  letterOverflow,
  rectangle,
  imageFile,
}

export default shapeBuilder;
