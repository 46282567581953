/*
 * Shape Shifter
 * http://www.kennethcachia.com/shape-shifter
 * A canvas experiment
 */

import Shape from './shape';
import ShapeBuilder from './shapeBuilder';
import Drawing from "./drawing";

var S = {
  init: function ({
    el,
    dotSize = 5,
    gap = 13,
    height = window.innerHeight,
    width = window.innerWidth
  }) {
    var action = window.location.href,
      i = action.indexOf("?a=");
    console.log(i);
    Drawing.init(el, width, height);
    ShapeBuilder.init(gap, width, height);
    Shape.setDotSize(dotSize);
    S.UI.init();
    // if (i !== -1) {
    //   S.UI.simulate(decodeURI(action).substring(i + 3));
    // } else {
    //   S.UI.simulate('Shape|Shifter|Type|to start|#icon thumbs-up|#countdown 3||');
    // }
    // S.UI.simulate('Shape|Shifter|Type|to start|#icon thumbs-up|#countdown 3||');

    Drawing.loop(function () {
      // 这里做绘制操作
      Shape.render();
    });
  },
  UI: (function () {
    var interval,
      isTouch = "ontouchstart" in window || navigator.msMaxTouchPoints,
      currentAction,
      time,
      maxShapeSize = 30,
      sequence = [],
      cmd = "#";

    function formatTime(date) {
      var h = date.getHours(),
        m = date.getMinutes();

      m = m < 10 ? "0" + m : m;
      return h + ":" + m;
    }

    function getValue(value) {
      return value && value.split(" ")[1];
    }

    function getAction(value) {
      value = value && value.split(" ")[0];
      return value && value[0] === cmd && value.substring(1);
    }

    function timedAction(fn, delay, max, reverse) {
      clearInterval(interval);
      currentAction = reverse ? max : 1;
      fn(currentAction);

      if (
        !max ||
        (!reverse && currentAction < max) ||
        (reverse && currentAction > 0)
      ) {
        interval = setInterval(function () {
          currentAction = reverse ? currentAction - 1 : currentAction + 1;
          fn(currentAction);

          if (
            (!reverse && max && currentAction === max) ||
            (reverse && currentAction === 0)
          ) {
            clearInterval(interval);
          }
        }, delay);
      }
    }

    function reset(destroy) {
      clearInterval(interval);
      sequence = [];
      time = null;

      if (destroy) {
        Shape.switchShape(ShapeBuilder.letter(""));
      }
    }

    function performAction(value) {
      var action, current;

      sequence = Array.isArray(value) ? value : sequence.concat(value.split("|"));
      if (sequence[0] && sequence[0].type === 'marquee') {
        return Shape.marquee(
          ShapeBuilder.letterOverflow(sequence[0].value),
          sequence[0].loop
        );
      }

      timedAction(
        function () {
          current = sequence.shift();
          if (typeof current === 'object') {
            action = current.type;
            value = current.value;
          } else {
            action = getAction(current);
            value = getValue(current);
          }

          switch (action) {
            case "countdown":
              value = parseInt(value, 10) || 10;
              value = value > 0 ? value : 10;

              timedAction(
                function (index) {
                  if (index === 0) {
                    if (sequence.length === 0) {
                      Shape.switchShape(ShapeBuilder.letter(""));
                    } else {
                      performAction(sequence);
                    }
                  } else {
                    Shape.switchShape(ShapeBuilder.letter(index), true);
                  }
                },
                1000,
                value,
                true
              );
              break;

            case "rectangle":
              value = value && value.split("x");
              value =
                value && value.length === 2
                  ? value
                  : [maxShapeSize, maxShapeSize / 2];

              Shape.switchShape(
                ShapeBuilder.rectangle(
                  Math.min(maxShapeSize, parseInt(value[0], 10)),
                  Math.min(maxShapeSize, parseInt(value[1], 10))
                )
              );
              break;

            case "circle":
              value = parseInt(value, 10) || maxShapeSize;
              value = Math.min(value, maxShapeSize);
              Shape.switchShape(ShapeBuilder.circle(value));
              break;

            case "time":
              var t = formatTime(new Date());

              if (sequence.length > 0) {
                Shape.switchShape(ShapeBuilder.letter(t));
              } else {
                timedAction(function () {
                  t = formatTime(new Date());
                  if (t !== time) {
                    time = t;
                    Shape.switchShape(ShapeBuilder.letter(time));
                  }
                }, 1000);
              }
              break;

            case "icon":
              ShapeBuilder.imageFile(
                "font-awesome/" + value + ".png",
                function (obj) {
                  Shape.switchShape(obj);
                }
              );
              break;
            case 'letter':
              Shape.switchShape(
                ShapeBuilder.letter(value)
              );
              break;
            default:
              Shape.switchShape(
                ShapeBuilder.letter(current[0] === cmd ? "What?" : current)
              );
          }
        },
        2000,
        sequence.length
      );
    }

    return {
      init: function () {
        if (isTouch) {
          document.body.classList.add("touch");
        }
      },

      simulate: performAction,
      reset,
    };
  })(),
};

export default S;
