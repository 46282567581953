import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './App.css';
import Main from './views/Main';
import About from './views/About';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/main" />
          </Route>
          <Route
            path="/main"
          >
            <Main />
          </Route>
          <Route
            path="/about"
          >
            <About />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
