import React, { useRef, useEffect } from 'react';
import Typed from 'typed.js';
import data from './data';
import styles from './style.module.css';

console.log(styles);
function Tiangou() {
  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: data.map(words => words.replace(/\|/g, ' ^1000\n ')),
      typeSpeed: 100,
      shuffle: true,
      backDelay: 1000,
      loop: true,
      backSpeed: 1
    });
    console.log(typed);
  }, []);
  return (
    <div className={styles.wrapper}>
      <div className={styles.pre}>
        <span ref={el}></span>
      </div>
    </div>
  )
}

export default Tiangou;
