import Point from './point';
import Color from './color';
import Drawing from './drawing';

const colorSet = [[255,255,255], [181,126,179]]

const rand = (min, max) => min + Math.floor((max - min) * Math.random());

export default class Dot {
  /**
   *
   * @param {number} x
   * @param {number} y
   * @param {number} size
   * @param {number} step dot移动的步长
   */
  constructor(x, y, size, step) {
    this.posi = new Point({
      x: x,
      y: y,
      z: size, // 半径radius
      a: 1, // alpha
      h: 0
    });

    this.shouldChangeColor = Math.random() > 0.9;
    this.colorIndex = 0;
    this.timestamp = Date.now() + rand(-300, 300);
    this.duration = rand(500, 1000);

    this.e = 0.07;
    this.s = true; // 控制是参与形状还是随机运动
    this.step = step;
    this.color = new Color(255, 255, 255, this.posi.a);

    this.target = this.clone();
    this.queue = [];
    this.listeners = new Map();

    this._x = x;
    this._y = y;
    this._size = size;
    this._step = step;
  }

  clone() {
    return new Point({
      x: this.x,
      y: this.y,
      z: this.z,
      a: this.a,
      h: this.h
    });
  }

  on(eventName, handler) {
    if (this.listeners.has(eventName)) {
      const listener = this.listeners.get(eventName);
      listener.push(handler);
    } else {
      this.listeners.set(eventName, [
        handler
      ]);
    }
  }

  reset() {
    this.posi.x = this._x;
    this.posi.y = this._y;
    this.step = this._step;
  }

  emit(eventName, payload) {
    const handlers = this.listeners.get(eventName);
    if (handlers && handlers.length) {
      handlers.forEach(handler => handler(payload));
    }
  }

  _draw() {
    this.color.a = this.posi.a;
    if (this.shouldChangeColor && Date.now() > this.timestamp + this.duration) {
      this.colorIndex = this.colorIndex >= colorSet.length - 1 ? 0 : this.colorIndex + 1;
      this.color = new Color(...colorSet[this.colorIndex], this.posi.a);
      this.timestamp = Date.now();
    }
    Drawing.drawCircle(this.posi, this.color);
  }
  /**
   * @description 判断是否到达某点
   * @param {Point} n
   * @returns {boolean}
   */
  _moveTowards(n) {
    var details = this.distanceTo(n, true),
        dx = details[0],
        dy = details[1],
        d = details[2];

    const e = this.step ? this.e * this.step : this.e * d;

    if (this.posi.h === -1) {
      this.posi.x = n.x;
      this.posi.y = n.y;
      return true;
    }

    if (d > 1) {
      // 防止d不够步长造成来回抖动
      if (this.step && d < this.step) {
        this.step = 0;
        this.stable = true;
      }
      this.posi.x -= ((dx / d) * e);
      this.posi.y -= ((dy / d) * e);
    } else {
      if (this.posi.h > 0) {
        this.posi.h--;
      } else {
        return true;
      }
    }

    return false;
  }

  _update() {
    var p,
        d;

    if (this._moveTowards(this.target)) {
      // 如果已经到达，从队列里取出下一点
      p = this.queue.shift();

      if (p) {
        this.target.x = p.x || this.posi.x;
        this.target.y = p.y || this.posi.y;
        this.target.z = p.z || this.posi.z;
        this.target.a = p.a || this.posi.a;
        this.posi.h = p.h || 0;
      } else {
        if (this.s) {
          this.emit('static', this);
          if (!this.stable) {
            this.posi.x -= Math.sin(Math.random() * Math.PI);
            this.posi.y -= Math.sin(Math.random() * Math.PI);
          }
        } else {
          this.move(new Point({
            x: this.posi.x + (Math.random() * 50) - 25,
            y: this.posi.y + (Math.random() * 50) - 25,
          }));
        }
      }
    } else {
      this.isStatic = true;
    }

    d = this.posi.a - this.target.a;
    this.posi.a = Math.max(0.1, this.posi.a - (d * 0.05));
    d = this.posi.z - this.target.z;
    this.posi.z = Math.max(1, this.posi.z - (d * 0.05));
  }

  distanceTo(n, details) {
    var dx = this.posi.x - n.x,
        dy = this.posi.y - n.y,
        d = Math.sqrt(dx * dx + dy * dy);

    return details ? [dx, dy, d] : d;
  }

  /**
   *
   * @param {Point} p
   * @param {boolean} avoidStatic
   */
  move(p, avoidStatic) {
    if (!avoidStatic || (avoidStatic && this.distanceTo(p) > 1)) {
      this.queue.push(p);
    }
  }

  render(callback) {
    this._update();
    this._draw();
    callback && callback(this);
  }
}
