import Drawing from "./drawing";
import Point from "./point";
import Dot from "./dot";

var dots = [], // 这里保存所有的dots
  width = 0,
  height = 0,
  cx = 0,
  cy = 0;

let _dotSize = 5;
let type = '';

function compensate() {
  var a = Drawing.getArea();

  cx = a.w / 2 - width / 2;
  cy = a.h / 2 - height / 2;
  console.log(cx, cy);
}

// 随机运动
function shuffleIdle() {
  var a = Drawing.getArea();

  for (var d = 0; d < dots.length; d++) {
    if (!dots[d].s) {
      dots[d].move({
        x: Math.random() * a.w,
        y: Math.random() * a.h,
      });
    }
  }
}

// 切换形状
function switchShape(n, fast) {
  const dotSize = _dotSize;
  var size,
    a = Drawing.getArea(),
    d = 0,
    i = 0;
  type = '';

  width = n.w;
  height = n.h;

  compensate();
  // 不够的话补更多的dot，在中心
  if (n.dots.length > dots.length) {
    size = n.dots.length - dots.length;
    for (d = 1; d <= size; d++) {
      dots.push(new Dot(a.w / 2, a.h / 2, dotSize));
    }
  }

  d = 0;
  // 参与形状的dot
  while (n.dots.length > 0) {
    i = Math.floor(Math.random() * n.dots.length);
    dots[d].e = fast ? 0.25 : dots[d].s ? 0.14 : 0.11;

    if (dots[d].s) {
      dots[d].move(
        new Point({
          z: Math.random() * dotSize * 4 + dotSize * 2,
          a: Math.random(),
          h: 18,
        })
      );
    } else {
      dots[d].move(
        new Point({
          z: Math.random() * dotSize + dotSize,
          h: fast ? 18 : 30,
        })
      );
    }

    dots[d].s = true;
    dots[d].move(
      new Point({
        x: n.dots[i].x + cx,
        y: n.dots[i].y + cy,
        a: 1,
        z: dotSize,
        h: 0,
      })
    );

    n.dots = n.dots.slice(0, i).concat(n.dots.slice(i + 1));
    d++;
  }
  // 随机运动的dot
  for (i = d; i < dots.length; i++) {
    if (dots[i].s) {
      dots[i].move(
        new Point({
          z: Math.random() * dotSize * 4 + dotSize * 2,
          a: Math.random(),
          h: 20,
        })
      );

      dots[i].s = false;
      dots[i].e = 0.04;
      dots[i].move(
        new Point({
          x: Math.random() * a.w,
          y: Math.random() * a.h,
          a: 0.3, //.4
          z: Math.random() * 4,
          h: 0,
        })
      );
    }
  }
}

// 实现滚动
/**
 * 
 * @param {object} n 包含dots, 
 * @param {*} loop 文字消失之后是否重新出现
 */
function marquee(n, loop) {
  let d = 0;
  let size = 0;
  const dotSize = _dotSize;
  const a = Drawing.getArea();
  type = 'marquee';
  // 不够的话补更多的dot，在中心
  if (n.dots.length > dots.length) {
    size = n.dots.length - dots.length;
    for (d = 0; d < size; d++) {
      let dot = new Dot(a.w + n.dots[d].x, n.dots[d].y, dotSize, 30);

      dots.push(dot);
    }
  }

  // 参与形状的dot
  const handler = (dot) => {
    dot.reset();
  }
  return new Promise((resolve) => {
    for(let d = 0; d < n.dots.length; d++){
      dots[d].e = 0.14;
  
      dots[d].s = true;
      dots[d].on('static', (dot) => {
        if (d === n.dots.length - 1) {
          resolve();
        }
        if (loop) {
          handler(dot);
        }
      });
      dots[d].move(
        new Point({
          x: n.dots[d].x + cx - n.w,
          y: n.dots[d].y + cy,
          a: 1,
          z: dotSize,
          h: 0,
        })
      );
    }
  })
}

function render() {
  const a = Drawing.getArea();
  for (var d = 0; d < dots.length; d++) {
    if (dots[d].x > a.w || dots[d].x < 0) {
      return;
    }
    if (type === 'marquee') {
      dots[d].render();
    } else {
      dots[d].render();
    }
  }
}

function setDotSize(dotSize) {
  _dotSize = dotSize;
}

const Shape = {
  render,
  switchShape,
  shuffleIdle,
  setDotSize,
  marquee,
};

export default Shape;
