import React, { useEffect } from 'react';
import Canvas from './canvas';
import Tiangou from './components/Tiangou';
import CloudWord from './components/cloud';
import styles from './style.module.css';
import Granim from 'granim';

function Main() {
  useEffect(() => {
    new Granim({
      element: '#granim-canvas',
      name: 'granim',
      opacity: [1, 1],
      states : {
          "default-state": {
              gradients: [
                  ['#cbebdb', '#D04ED6'],
                  ['#1CD8D2', '#93EDC7']
              ]
          }
      }
   });
  }, []);
  return (
    <>
      <canvas id="granim-canvas" className={styles.granim}></canvas>
      <Canvas />

      <div className={styles.main}>
        <h3>♥️每日情话</h3>
        <Tiangou />
        <h3>日常</h3>
        <CloudWord />
        <h3>纪念日</h3>
        <a href="https://spl-100-days.vercel.app/#/bored">100天</a>
      </div>

    </>
  )
}
export default Main;
