export default [
  ['郭娇阳', 58],
  ...Array.from({length: 5}).fill([
    ['水龙头事件', 15],
    ['王者荣耀', 18],
    ['2340条例', 15],
    ['我跟你说', 13],
    ['酸奶怪', 13],
    ['太过分了', 15],
    ['再说点啥', 18],
    ['收拾屋子', 18],
    ['怎么了嘛', 18],
    ['时间黑洞', 24],
    ['咸惠', 25],
    ['说风就是雨', 16],
    ['缚姐', 16],
    ['王者荣耀', 16],
    ['送钥匙', 16],
    ['睡客厅', 16],
    ['吃土模式', 16],
    ['💗', 24],
    ['再说十分钟', 14],
    ['异地4.15', 24],
    ['心机boy', 13],
    ['老头子', 13],
    ['路上不安全', 15],
  ]).flat()
]
