import React, { useEffect } from 'react';
import S from '../../utils/shape-shifter-es/index';
import styles from './style.module.css';
import dayjs from 'dayjs';
const START = '2020-09-11';



function getContent() {
  const days = dayjs().diff(dayjs(START), 'day');
  const thisYear = dayjs().year();
  const thisMon = dayjs().month();
  const thisDay = dayjs().date();

  if (thisMon === dayjs(START).month() && thisDay === dayjs(START).date()) {
    return `在一起 ${thisYear - 2020} 周年啦～`
  }
  return `在一起${days}天了`
}

function Canvas() {
  useEffect(() => {
    S.init({
      el: document.getElementById('canvas'),
      width: window.innerWidth,
      height: window.innerHeight / 2
    });
    // S.UI.simulate(`G❤️G`);
    S.UI.simulate([{
      type: 'marquee',
      value: getContent()
    }]).then(() => {
      S.UI.simulate([{
        type: 'letter',
        value: 'G❤️G'
      }]);
    });
  }, []);
  return (<canvas id="canvas" className={styles.canvas}/>)
}

export default Canvas;
